@import 'variables';

@media all and (max-width:$sm-width) {

  .advantages {
    margin-left:$xs-padding;
    margin-right:$xs-padding;

    &__video {
      float:none;

      width:100%;
      height:174px;

      .icon-play {
      }

      &-title {
        font-size:14px;
      }
    }

    &__list {
      margin:0;

      &-item {
        text-align:center;

        &, &:first-of-type {
          margin-top:40px;
        }

        &__content {
          margin-left:0;
          max-width:none;

          h3 {
            font-size:18px;
            line-height:22px;
          }

          p {
            font-size:14px;
            line-height:20px;

            margin-top:10px;
          }
        }

        &__icon {
          display:inline-block;
          vertical-align:top;

          float:none;

          margin-bottom:15px;
        }
      }
    }
  }
}
