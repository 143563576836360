@import 'variables';

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video, xmp, input, textarea, button {
  border:0;
  font-size:100%;
  margin:0;
  padding:0
}

html, body {
  height:100%;
  position:relative;

  -webkit-font-smoothing:antialiased;
  backface-visibility:hidden;
  -webkit-text-size-adjust:none;
}

html, body,
input, textarea, button {
  font-family:Muller, Arial, sans-serif;
  font-weight:400;

  font-size:16px;
  line-height:22px;
}

button {
  cursor:pointer;
}

::-webkit-input-placeholder {
  color:$color-placeholder;
}

:-moz-placeholder {
  color:$color-placeholder;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display:block;
}

code, kbd, pre, samp {
  font-family:Menlo, Monaco, Consolas, "Courier New", monospace;
}

pre {
  display:block;
  padding:9.5px;
  margin:0 0 10px;
  font-size:13px;
  line-height:1.42857143;
  color:#333;
  word-break:break-all;
  word-wrap:break-word;
  background-color:#f5f5f5;
  border:1px solid #ccc;
  border-radius:4px;
}

hr {
  display:block;
  width:100%;
  height:1px;

  float:none;
  clear:both;
  font:0/0 d;

  background:#ccc;
  margin:25px 0;
}

b, strong {
  font-weight:700;
}

img {
  color:transparent;
  font-size:0;
  vertical-align:middle;
  -ms-interpolation-mode:bicubic
}

li {
  display:list-item;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

th, td, caption {
  font-weight:normal;
  vertical-align:top;
  text-align:left;
}

svg {
  overflow:hidden;
}

input, textarea, button, a {
  outline:none;
  resize:none;
}

*, :before, :after {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
}

ol, ul, li {
  list-style:none inside;
}

a {
  text-decoration:none;
  cursor:pointer;
  color:$color-link;
}

a:hover, .btn-link:hover {
  text-decoration:none;
  color:$color-hover;
}

a:active, a:hover, .btn-link:hover, .btn-link:active {
  outline:0;
}

a:focus, .btn-link:focus {
  text-decoration:none;
}

.btn-link {
  background:none;
}

.fll {
  float:left;
}

.flr {
  float:right;
}

.pr {
  position:relative;
}

.tac {
  text-align:center;
}

.ovh {
  overflow:hidden;
}

.clfix {
  display:block;
  clear:both;
  float:none;
  width:100%;
  height:0;
  margin:0;
  padding:0;
  position:relative;
  font:0/0 d;
}

%clearfix {
  &:after {
    content:'';

    display:table;
    clear:both;

    font:0/0 d;
  }
}
