@import 'variables';

.range-slider {

  user-select:none;

  padding-top:9px;

  &__line {
    @extend .pr;

    font-size:0;
    line-height:0;

    width:100%;
    height:4px;

    box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
    background:#e9f1f3;

    &-select {
      display:block;

      position:absolute;
      top:0;
      left:0;

      height:4px;

      background:#ebf1f4 linear-gradient(to right, #16beb1 0%, #16beb1 49%, #51d591 100%);
      box-shadow:inset 0 -1px 3px rgba(23, 69, 88, 0.22);
    }

    &-handler {
      display:block;
      width:20px; // 22
      height:22px; // 23

      cursor:pointer;

      position:absolute;
      top:0;
      right:0;

      margin:-8px -10px 0 0;

      background:url(imgs/range-handler.png) 0 0 no-repeat;

      &:hover {
        background-position:bottom left;
      }
    }
  }

  &__values {
    @extend .pr;

    display:inline-block;
    vertical-align:top;

    margin-top:19px;
    line-height:1;

    white-space:nowrap;
    font-size:14px;
    color:#8fa8b4;

    i {
      display:inline-block;
      vertical-align:top;
      font-style:normal;
    }
  }
}