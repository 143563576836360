@import 'variables';

.layout-index {

  .header {

    &-wrapper {
      background:none;
    }

    .header {

      &-lang, &-auth {

        span {
          color:#fff;
        }

        > i {
          background:#fff;
        }
      }

      &-cart {

        .icon-cart {
          background-image:url(imgs/i-cart-white.png);
        }
      }

      &-auth {

        .icon-login {
          background-image:url(imgs/i-login-white.png);
        }

        .icon-reg {
          background-image:url(imgs/i-reg-white.png);
        }
      }
    }

    a {
      color:#fff;

      &:hover {
        color:#c9ff69;
      }
    }
  }
}

.index-bg {
  position:absolute;
  width:100%;
  height:100%;

  font:0/0 d;

  z-index:-1;

  pointer-events:none;

  &-1 {
    background:top center no-repeat;
  }

  &-2 {
    background:center no-repeat;
  }

  &-3 {
    background:center no-repeat;
  }

  &-4 {
    background:100% 92% no-repeat;
  }
}

.index-block {

  @extend .tac, .pr;

  margin-bottom:130px;

  &-title {
    font-size:32px;
    line-height:38px;
    font-weight:700;
    color:#3e758b;

    margin-bottom:30px;
  }

  &-subtitle {
    font-size:16px;
    line-height:22px;
    color:$color-link;

    margin:-10px 0 30px;
  }

  &-reviews {

    .index-block-title {
      margin-bottom:25px;
    }

    .index-block-btn {
    }

    .swiper-container {
      padding:0 20px 30px;
    }

    .swiper-pagination {
      display:none;
    }

    .b-reviews-list {
      margin:0;

      &__item {
        display:inline-block;
        vertical-align:top;

        width:385px;
        margin:0 5px;

        &:first-child {
        }
      }
    }
  }

  &-actions {

    .index-bg-2 {
      top:50%;
      left:0;

      height:1014px;

      margin-top:-457px;
    }

    .actions-list {
      @extend .pr;

      margin:0;
      height:414px;

      &__item {
        position:absolute;
        margin:0;

        &:nth-child(1) {
          top:0;
          left:0;

          width:383px;
        }

        &:nth-child(2) {
          top:0;
          left:0;

          margin-left:401px;

          width:282px;
        }

        &:nth-child(3) {
          top:0;
          right:0;
          bottom:0;

          width:483px;
          height:auto;
        }

        &:nth-child(4) {
          left:0;
          bottom:0;

          width:286px;
        }

        &:nth-child(5) {
          left:0;
          bottom:0;

          margin-left:300px;

          width:384px;
        }
      }
    }
  }

  &-sales {
    @extend .pr;

    .index-bg-3 {
      top:50%;
      left:0;

      height:1563px;

      margin-top:-781px;
    }

    .products-list {
      @extend .pr;

      margin:0;
      height:414px;

      &__item {
        position:absolute;
        margin:0;

        &:nth-child(1) {
          top:0;
          left:0;
          bottom:0;

          width:483px;
          height:auto;
        }

        &:nth-child(2) {
          top:0;
          right:0;

          margin-right:302px;

          width:383px;
        }

        &:nth-child(3) {
          top:0;
          right:0;

          width:282px;
        }

        &:nth-child(4) {
          right:0;
          bottom:0;

          margin-right:401px;

          width:286px;
        }

        &:nth-child(5) {
          right:0;
          bottom:0;

          width:384px;
        }
      }
    }
  }

  &-top-banners {
    margin-bottom:45px;
  }

  &-banners {

    .swiper-pagination {
      display:none;
    }
  }

  &-btn {
    margin-top:35px;
  }

  &-payments {
  }

  &-calc {
    margin-top:153px;

    .calc {
    }
  }

  &-blog {

    .index-block-btn {
      margin-top:20px;
    }

    .swiper-container {
      padding:0 0 9px;
    }

    .blog-list {

      &__item {
        text-align:left;
      }
    }
  }
}

@import 'index-page-zmedia';
