@import 'variables';

@media all and (max-width:$sm-width) {

  .content {
    font-size:14px;
    line-height:20px;

    br {
      display:none;
    }

    h1 {
      font-size:24px;
      line-height:26px;
    }

    h2 {
      font-size:18px;
      line-height:22px;
    }

    .video {

      iframe {
        max-width:100%;
      }
    }

    p {
      margin-bottom:11px;
    }

    .content-links-table {

      margin:0 auto;

      > * {
        width:50%;

        &:nth-child(3) {

          &:after {
            left:auto;
            right:0;
          }
        }

        &:nth-child(4) {

          &:after {
            content:none;
          }

          &:before {
            content:'';

            position:absolute;
            bottom:0;
            left:8px;
            right:8px;

            height:2px;

            background:#e9f1f3;
          }
        }
      }
    }

    .content-img-plate {
      height:auto;
      white-space:nowrap;
      overflow:visible;

      margin-left:-$xs-padding;
      margin-right:-$xs-padding;

      img {

        &, &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
          position:relative;
          margin:0 !important;

          display:inline-block;
          vertical-align:top;

          height:147px;
        }
      }
    }

    blockquote {
      padding:25px 10px 24px;

      h2 {
        padding-left:24px;

        &:before {
          margin:0 0 0 0;
        }
      }
    }
  }
}
