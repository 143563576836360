@import 'variables';

.payments {
  box-shadow:0 8px 32px rgba(37, 105, 132, 0.36);

  background:#fff;

  &__tabs {
    font-size:0;
    line-height:0;
    white-space:nowrap;

    a {
      display:inline-block;
      width:50%;
      height:90px;

      box-shadow:inset 0 -5px 5px rgba(37, 105, 132, 0.11);
      background-color:#e9f1f3;

      font-size:18px;
      line-height:96px;
      font-weight:700;
      color:#8fa8b4;

      text-transform:uppercase;
      vertical-align:top;
    }

    .active {
      box-shadow:none;
      background:#fff;
      cursor:default;
      color:$color-link;
    }
  }

  &__content {

    &-item {
      display:none;

      padding:100px 0 133px;

      max-width:1040px;
      margin:0 auto;

      &:nth-child(2) {
        padding:41px 0 74px;
      }

      &__top-text {

        h3 {
          font-size:22px;
          line-height:26px;
          font-weight:700;
          color:$color-link;
        }

        p {
          font-size:16px;
          line-height:22px;
          color:$color-link;

          margin:7px 0 37px;
        }
      }

      &__points {
        font-size:0;
        line-height:0;
        white-space:nowrap;

        &-item {
          @extend .pr;

          display:inline-block;
          width:33.3%;

          white-space:normal;
          vertical-align:top;

          &__title {
            font-size:22px;
            line-height:41px;
            font-weight:700;
            color:$color-link;

            .icon {
              margin-right:7px;

              &-n1 {
                margin-left:-47px;
              }

              &-n2 {
                margin-left:-25px;
              }

              &-n3 {
                margin-left:-14px;
              }
            }
          }

          &__lead {
            font-size:16px;
            line-height:22px;
            color:$color-link;

            margin:10px 0 24px;
            min-height:66px;
          }

          > .btn {
            padding:0;
            width:240px;
          }

          &:nth-child(2) {

            &:before, &:after {
              content:'';

              position:absolute;
              top:0;
              left:0;

              width:2px;
              height:100%;

              background:#e9f1f3;
            }

            &:after {
              left:auto;
              right:0;
            }
          }
        }
      }
    }

    &-banner {
      @extend .pr;
      background:#ebfeef;

      margin:43px auto 36px;
      max-width:590px;

      font-size:22px;
      line-height:26px;
      font-weight:700;
      color:$menu-active-link;

      padding:15px 60px 10px;

      .icon {
        position:absolute;
        top:50%;
        left:0;

        &-box {
          left:auto;
          right:0;

          margin:-42px -36px 0 0;
        }

        &-calendar {
          margin:-35px 0 0 -38px;
        }
      }
    }

    .text-muted {
      max-width:780px;
      margin:0 auto;

      p {
        a {
          text-decoration:underline;

          &:hover {
            color:$color-hover;
            text-decoration:none;
          }
        }
      }
    }
  }
}

@import 'payments-zmedia';
