@import 'variables';

.cart {

  margin-top:25px;

  &-view {
    margin:0 auto;
    max-width:880px;

    .g-page-title {
      margin-top:36px;
    }
  }

  &__item {
    position:relative;

    font-size:16px;
    line-height:22px;
    color:$color-link;

    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:start;

    padding:15px 0;
    border-bottom:2px solid #e9f1f3;

    &-product {
      flex:1;

      a {
        display:inline-flex;
        flex-direction:row;
        vertical-align:top;
      }

      &__photo {
        box-shadow:0 8px 21px rgba(66, 112, 131, 0.3);
        background:#fff no-repeat center;
        background-size:contain;

        width:80px;
        height:80px;
      }

      &__title {
        flex:1;
        margin:10px 0 0 20px;

        b {
          text-transform:uppercase;
        }
      }
    }

    &-price {
      width:140px;

      padding-top:14px;

      font-size:0;
      line-height:0;

      b {
        display:block;

        font-size:16px;
        line-height:1;
      }

      u {
        @extend .pr;

        display:inline-block;
        vertical-align:top;
        text-decoration:none;

        font-size:14px;
        line-height:1;
        color:#1a5e78;

        &:after {
          content:'';
          display:block;
          height:2px;
          width:100%;
          position:absolute;
          top:50%;
          left:0;
          margin:-1px 0 0;
          background:#00af67;
        }
      }
    }

    &-total {
      width:100px;

      padding-top:14px;

      font-size:0;
      line-height:0;
      text-align:right;

      b {
        font-size:16px;
        line-height:1;
      }
    }

    &-actions {
      width:95px;

      padding-top:13px;

      font-size:0;
      line-height:0;
      text-align:right;

      i {
        cursor:pointer;
      }
    }

    &-amount {
      width:123px;

      label {
      }

      input[type="text"] {
        width:68px;
        margin:0;
      }
    }
  }

  &-list {
    margin:0 0 20px;

    &__header {
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      align-items:start;

      font-size:0;
      line-height:0;

      border-bottom:2px solid #e9f1f3;

      > * {
        font-size:16px;
        line-height:20px;
        color:#8fa8b4;

        padding-top:0;
      }

      .cart__item {

        &-product {
        }

        &-price {
        }

        &-total {
        }

        &-actions {
        }

        &-amount {
        }
      }
    }
  }

  &__bottom {
    @extend %clearfix;

    color:$color-link;
    line-height:48px;

    span {
      font-weight:700;

      b {
        color:$menu-active-link;
      }
    }

    .btn {
      float:right;
    }
  }
}

@import 'cart-zmedia';
