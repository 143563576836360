.lightbox {
  display:none;
  width:520px;

  position:absolute;
  top:0;
  left:50%;
  z-index:110;

  background:#fff;
  margin:50px 0 50px -260px;

  &-wrapper {
    display:none;
    width:100%;
    height:100%;
    min-width:1170px;

    overflow-x:hidden;
    overflow-y:auto;

    background:#587994;
    background:rgba(0, 58, 80, .4);

    position:fixed;
    top:0;
    left:0;
    z-index:110;
  }

  &__inner {

    &-wrapper {
    }
  }

  &__close {
    @extend .icon, .icon-remove;

    position:absolute;
    top:0;
    right:0;

    margin:20px;
    cursor:pointer;
  }

  &-content {
    padding:55px 50px 50px;
  }

  &-desc {
    @extend .tac;

    font-size:22px;
    line-height:26px;
    font-weight:700;
    color:#1a5e78;

    margin-bottom:20px;
  }

  &-buttons {

    .btn {
      display:block;
      width:300px;

      margin:20px auto 0;
      padding:0;
    }
  }

  &-order {
    text-align:center;
  }

  &-feedback, &-question {
    width:580px;
    margin-left:-290px;

    .lightbox {

      &-content {
        padding:55px 100px 60px;
      }

      &__form {

        &-buttons {
          margin-bottom:0;

          .btn {
            padding:0;
            width:100%;
          }
        }
      }
    }
  }
}
