@import 'variables';

@media all and (max-width:$lg-width) {

  .index-block {

    &-reviews,
    &-banners {

      .swiper-pagination {
        display:block;
      }
    }

    &-banners {

      .swiper-container {
        padding-bottom:30px;
      }
    }

    &-actions,
    &-sales {

      .swiper-container {
        padding:0 20px 30px;
      }
    }
  }
}

@media all and (max-width:$sm-width) {

  .layout-index {

    .content-wrapper {
      padding-bottom:270px;
    }

    .mobile-menu {

      &-trigger {

        & {
          border-color:#fff;
        }

        &:before, &:after {
          background:#fff;
        }
      }
    }

    .calc {
      padding:0 $xs-padding;

      &:before {
        display:none;
      }

      form {
        display:none;
      }

      &-title {
        margin:0;

        font-size:24px;
        line-height:26px;

        span {
          padding:0;

          background:none;
        }
      }

      &-subtitle {
        margin:14px 0 0;

        font-size:14px;
        line-height:20px;
      }
    }
  }

  .index-block {

    &-title {
      font-size:24px;
      line-height:26px;

      margin:0;
    }

    &-subtitle {
      font-size:14px;
      line-height:20px;

      margin:14px 0 21px;
    }

    &-reviews {
      margin:75px 0 0;

      .index-block-btn {
        margin-top:30px;
      }

      .b-reviews-list {
        margin-top:30px;

        &__item {
          width:280px;

          margin:0;
        }
      }
    }

    &-top-banners {
      margin:0;
    }

    &-banners {
      margin:80px 0 0;
    }

    &-btn {
    }

    &-sales {
      margin:90px 0 0;

      .products-list {

        height:auto;
        white-space:nowrap;

        &__item {

          &, &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            display:inline-block;
            vertical-align:top;

            position:relative;
            top:auto;
            left:auto;
            right:auto;
            bottom:auto;

            margin:0;

            width:280px;
            height:170px;

            white-space:normal;
          }
        }
      }
    }

    &-payments {
      margin:30px 0 0;
    }

    &-calc {
      margin:75px 0 0;
    }

    &-actions {
      margin:75px 0 0;

      .actions-list {
        max-width:none;
        height:auto;

        white-space:nowrap;

        &__item {

          &, &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
            display:inline-block;
            vertical-align:top;

            position:relative;
            top:auto;
            left:auto;
            right:auto;
            bottom:auto;

            margin:0;

            width:280px;
            height:170px;

            white-space:normal;
          }
        }
      }
    }

    &-advantages {
      margin:80px 0 0;
    }

    &-blog {
      margin:75px 0 0;

      .blog-list {
        margin:25px 0 0;

        white-space:nowrap;

        &__item {
          display:inline-block;
          white-space:normal;

          margin:0 10px;
        }
      }
    }
  }
}
