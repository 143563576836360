@import 'variables';

.mobile-menu {
  @extend .pr;

  display:none;
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;

  background:#e9f1f3;

  padding:75px $xs-padding 0;

  z-index:8000;

  &__close {
    position:absolute;
    top:0;
    left:0;

    margin:20px;

    cursor:pointer;
  }

  &-trigger {
    display:none;

    position:absolute;
    top:50%;
    left:0;

    width:24px;
    height:18px;

    margin:-9px 20px 0;

    border-top:2px solid $color-link;

    font-size:0;
    line-height:0;
    text-decoration:none;

    &:before,
    &:after {
      content:'';

      display:block;
      width:100%;
      height:2px;

      background:$color-link;

      position:absolute;
      top:50%;
      left:0;

      margin-top:-2px;
    }

    &:after {
      top:auto;
      bottom:0;
    }
  }

  .header {

    &-lang {
      float:none;

      position:absolute;
      top:0;
      right:0;

      margin:20px;

      color:$color-hover;

      > i {
        margin:0 15px;
      }
    }

    &-auth {
      display:block;

      margin:0;

      a {
        font-size:14px;
        line-height:22px;

        font-weight:300;
      }

      > i {
        height:22px;

        margin:0 20px;
      }
    }

    &-menu {
      display:block;

      margin:20px 0 0;

      ul {

        li {
          display:block;

          margin:0;

          font-size:14px;
          line-height:51px;

          height:auto;

          border-bottom:1px solid #c0d4da;

          &.active {

            &:before {
              display:none;
            }
          }
        }
      }
    }
  }
}
