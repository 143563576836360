@import 'variables';

@media all and (max-width:$sm-width) {

  .prices {

    &-table-main,
    &-table-fixed {
      font-size:12px;
      line-height:16px;

      td {
        padding:15px 10px;
      }
    }

    &-table__main {

      &-wrapper {
        margin-bottom:20px;
        margin-right:-$xs-padding;
      }
    }

    &-info {

      margin-top:40px;

      h2 {
        font-size:18px;
        line-height:22px;
      }

      p {
        font-size:14px;
        line-height:20px;

        margin:0 0 20px;
      }

      .btn {
        padding-left:0;
        padding-right:0;

        width:100%;
      }
    }
  }
}