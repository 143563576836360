@import 'variables';

.btn {
  @extend .tac, .pr;
  border-radius:22px;

  display:inline-block;
  position:relative;
  z-index:1;

  height:41px;
  padding:0 60px;

  font-size:16px;
  line-height:43px;
  font-weight:700;

  text-decoration:none;
  white-space:nowrap;
  cursor:pointer;
  color:#3e758c;

  background:#e9f1f3;

  &:hover {
    background:#ebfeef;
    color:$menu-active-link;
  }

  &:active {
    color:$menu-active-link-transparent;
  }

  &-colored {
    background:#e9f1f3 linear-gradient(-233deg, #68de84 0%, #13bdb3 75%, #00adaf 100%);
    color:#fff;

    &:hover {
      color:#fff;
      background:$menu-active-link linear-gradient(-233deg, #00c1b1 0%, #0093a2 75%, #00849e 100%);
    }

    &:active {
      background:$menu-active-link linear-gradient(-233deg, #00c1b1 0%, #0093a2 75%, #00849e 100%);
      color:rgba(255, 255, 255, .7);
    }
  }

  &-dark-blue {
    background:$color-link;
    color:#fff;

    &:hover {
      color:#fff;
      background:$color-link linear-gradient(-233deg, #00c1b1 0%, #0093a2 75%, #00849e 100%);
    }

    &:active {
      background:$color-link linear-gradient(-233deg, #00c1b1 0%, #0093a2 75%, #00849e 100%);
      color:rgba(255, 255, 255, .7);
    }
  }
}

.g-page-title {
  font-size:32px;
  line-height:34px;
  font-weight:700;
  color:$color-link;

  margin:0 0 20px;
}

.text-muted {
  font-size:14px;
  line-height:18px;
  color:#8fa8b4;
}

.link2back {
  @extend .pr;

  font-size:16px;
  line-height:1;

  display:inline-block;
  vertical-align:top;

  a {
    text-decoration:underline;
    color:#8fa8b4;

    &:hover {
      color:$color-hover;
    }
  }

  margin-left:26px;

  .icon {
    position:absolute;
    top:0;
    left:0;

    vertical-align:middle;

    margin:2px 0 0 -26px;
  }

  &.reverse {
    text-align:right;

    margin-left:0;
    margin-right:26px;

    .icon {
      left:auto;
      right:0;

      margin:0 -26px 0 0;
    }
  }
}

.link-grey {
  @extend .link2back;

  .icon {
    margin-top:-1px;
  }
}

.link {

  &_icon {

    &:before {
      content:'';

      display:block;
      position:absolute;
      top:0;
      left:0;

      margin:-1px 0 0 -26px;
    }

    &-protect {

      &:before {
        @extend .icon-protect;
      }
    }

    &-extend {

      &:before {
        @extend .icon-extend;
      }
    }

    &-attention {

      &:before {
        @extend .icon-attention;
      }
    }

    &-hand {

      &:before {
        @extend .icon-hand;
      }
    }
  }
}

.socials-share {
  font-size:0;
  line-height:0;
  white-space:nowrap;

  margin-top:40px;

  span {
    display:inline-block;
    vertical-align:middle;

    font-size:16px;
    line-height:43px;
    font-weight:700;
    color:#1a5e78;

    margin-right:10px;
  }

  .icon {
    margin:0 5px;
  }
}

form {

  .label-, .control-label {
    display:block;

    font-size:16px;
    line-height:1;
    color:#8fa8b4;
  }

  input[type="text"], textarea {
    box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
    border-radius:0;
    border:none;

    background:#e9f1f3;

    margin-top:8px;
    padding:0 20px;

    display:block;
    height:42px;
    width:100%;

    font-size:16px;
    line-height:42px;
    color:$color-link;

    &:focus, &:active {
      background:#f4f8f9;
    }
  }

  fieldset {
    margin-bottom:20px;
  }

  textarea {
    height:85px;
  }

  select {
    display:inline-block;
    vertical-align:middle;

    height:42px;

    font-size:16px;
    line-height:42px;

    padding:0 20px;
  }

  .inline {

    label {
      display:inline-block;

      line-height:24px;
      vertical-align:top;
      cursor:pointer;

      .label- {
        display:inline-block;

        font-size:16px;
        line-height:22px;
        color:#8fa8b4;

        margin-left:10px;

        a {
          text-decoration:underline;
          color:#8fa8b4;

          &:hover {
            text-decoration:none;
            color:$menu-active-link;
          }
        }
      }

      input:checked ~ span {
        color:$color-link;
      }
    }
  }

  .autocomplete {

    input[type="text"] {
      margin-right:42px;

      width:311px;
    }

    &.inline {

      .label- {
        margin-left:0;
        margin-right:10px;
      }
    }
  }

  .amount-wrapper {

    label {
      @extend .pr;

      display:inline-block;
      vertical-align:top;

      padding-right:42px;

      input {
        @extend .pr;
        z-index:3;
      }

      u, b {
        display:block;
        width:42px;
        height:21px;

        position:absolute;
        top:0;
        right:0;

        z-index:2;

        background:#e9f1f3 url(imgs/i-cart-up.png) center no-repeat;
        box-shadow:-1px 1px 2px rgba(26, 94, 120, 0.22);

        cursor:pointer;
      }

      b {
        box-shadow:none;
        background-image:url(imgs/i-cart-down.png);

        z-index:1;

        top:auto;
        bottom:0;
      }
    }
  }

  .has-error {

    input[type="text"], textarea, .icon-checkbox {
      background:rgba(205, 0, 0, .15);
    }

    &.inline {

      label {

        .label- {
          color:#cd0000;
        }
      }
    }
  }
}

.rating {
  @extend .pr, .ovh;

  display:block;

  font-size:0;
  line-height:0;

  width:80px;
  height:14px;

  background:url(imgs/rating.png) 0 0 no-repeat;

  &_1 {
    background-position:0 -21px;
  }
  &_2 {
    background-position:0 -42px;
  }
  &_3 {
    background-position:0 -63px;
  }
  &_4 {
    background-position:0 -84px;
  }
  &_5 {
    background-position:0 -105px;
  }
}

.show-on-sm {
  display:none;
}

.select2-container {
  outline:none;

  .select2-selection--single {
    outline:none;

    box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
    border-radius:0;
    border:none;

    background:#e9f1f3;

    display:block;
    height:42px;
    width:auto;

    margin-right:42px;

    .select2-selection__rendered {
      outline:none;

      padding:0 20px;

      font-size:16px;
      line-height:42px;

      text-align:left;
      color:$color-link;
    }

    .select2-selection__arrow {
      position:absolute;
      bottom:0;
      right:0;
      top:auto;

      width:42px;
      height:42px;

      background:#e9f1f3 url(imgs/rectangle-36.png) center no-repeat;

      b {
        display:none;
      }
    }
  }

  &--open, &:hover {

    .select2-selection--single {
      background:#f4f8f9;

      .select2-selection__arrow {
        background-color:#f4f8f9;
      }
    }
  }

  .select2-dropdown {
    border:none;

    .select2-search--dropdown {
      background:#f4f8f9;

      .select2-search__field {
        box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
        border-radius:0;
        border:none;

        background:#fff;

        display:block;
        height:42px;

        padding:0 20px;

        font-size:16px;
        line-height:42px;

        text-align:left;
        color:$color-link;
      }
    }

    .select2-results__options {
      box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
      background:#f4f8f9;
      color:$color-link;

      .select2-results__option {
        font-size:16px;
        line-height:42px;

        padding:0 0 0 20px;

        &--highlighted[aria-selected],
        &[aria-selected=true] {
          background-color:#ebfeef;
          color:$color-hover;
        }

        &[aria-selected=true] {
        }
      }
    }
  }
}

.edit-link {
  position:absolute;
  top:0;
  right:0;

  z-index:200;

  a {
    font-size:32px;
    color:#cd0000;

    &:hover {
      text-decoration:underline;
    }
  }
}

.list-view {

  .empty {
    font-size:16px;
    line-height:1;

    margin-bottom:15px;
  }
}

/*.select-toggle {
  display:block;
  width:42px;
  height:42px;

  position:absolute;
  bottom:0;
  right:0;

  cursor:pointer;

  background:#e9f1f3 url(imgs/rectangle-36.png) center no-repeat;

  &:hover {
    background-color:#f4f8f9;
  }
}*/

@import 'common-zmedia';
