@import 'variables';

.shop-view {

  @extend %clearfix;

  &__col {

    &-left {
      @extend .fll;

      width:255px;
    }

    &-right {
      margin-left:300px;
    }
  }

  .categories-actions {
    line-height:1;

    margin-top:10px;

    a {
      font-size:14px;
      font-weight:700;
    }
  }
}

.layout-shop-item {

  .link2back {
    float:right;

    margin-top:11px;
  }
}

@import 'shop-view-zmedia';