@import 'variables';

.infoblock {
  display:none;

  position:fixed;
  bottom:0;
  left:0;

  z-index:9000;

  width:100%;
  height:76px;

  background:$color-link;

  &__wrapper {
    padding-top:20px;
  }

  &__text {
    margin:0 auto;
    max-width:950px;

    text-align:center;
    font-size:14px;
    line-height:22px;
    color:#fff;

    display:flex;
    flex-direction:row;

    .icon {
    }

    &-content {
      flex:1;
      max-width:710px;

      text-align:left;

      margin:0 30px 0 20px;
    }

    .btn {
      text-transform:uppercase;
    }
  }
}

@import 'infoblock-zmedia';
