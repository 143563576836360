@import 'variables';

@media all and (max-width:$sm-width) {

  .actions-list {
    width:100%;
    max-width:380px;

    margin:0 auto 30px;

    &__item {
      display:block;
      width:auto;

      margin-left:0;
      margin-right:0;

      &-title {
        font-size:14px;
        line-height:20px;
      }

      &-action-text {
        font-size:14px;
        line-height:20px;

        u {
          font-size:18px;
        }
      }

      &-action-price {
      }

      &-shop {
        font-size:14px;
      }
    }
  }
}
