@import 'variables';

.product-page {
}

.product {
  @extend .pr;

  &-widget {

    margin-top:80px;

    h3 {
      font-size:22px;
      line-height:26px;
      font-weight:700;
      color:$color-link;

      margin:0 0 23px;
    }

    .products-list,
    .actions-list {
      margin-bottom:30px;

      &__item {
        margin-bottom:0;
      }
    }

    &.similar-products {
      margin-top:70px;
    }
  }

  &__photos {
    @extend .fll, %clearfix;

    &-list {
      @extend .fll;

      font:0/0 d;

      width:80px;

      i {
        @extend .pr;

        display:inline-block;
        vertical-align:top;

        width:80px;
        height:80px;

        box-shadow:0 8px 21px rgba(66, 112, 131, 0.3);

        background:#fff no-repeat center;
        -webkit-background-size:contain;
        background-size:contain;

        cursor:pointer;

        margin:0 0 20px;

        &:last-of-type {
          margin-bottom:0;
        }

        &.active {

          &:after {
            content:'';

            display:block;
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;

            border:4px solid #d7fde0;
          }
        }
      }
    }

    &-main {
      box-shadow:0 8px 21px rgba(66, 112, 131, 0.3);
      background:#fff no-repeat center;
      background-size:contain;

      width:460px;
      height:380px;

      margin-left:100px;

      img {
      }
    }
  }

  &__info {
    margin-left:601px;
    max-width:500px;

    &-title {
      font-size:32px;
      line-height:34px;
      font-weight:700;
      color:$color-link;
    }

    &-rating {
      @extend .pr;

      margin:8px 0 10px;
    }

    &-mobile-photos {
      display:none;
      white-space:nowrap;

      margin-left:-$xs-padding;
      margin-right:-$xs-padding;

      .photo {
        width:280px;
        height:230px;

        box-shadow:0 8px 21px rgba(66, 112, 131, 0.3);
        transition:box-shadow .4s;

        display:inline-block;
        vertical-align:text-top;

        text-align:left;
        background:#fff no-repeat bottom right;
      }
    }

    &-lead {
    }

    &-buy-wrapper {
      margin-top:26px;
    }

    &-price {
      margin-bottom:33px;

      span {
        display:block;

        font-size:16px;
        line-height:1;
        color:#8fa8b4;

        margin:0 0 8px;
      }

      u, i {
        display:inline-block;
        vertical-align:top;

        font-weight:700;
      }

      u {
        font-size:32px;
        line-height:1;
        color:$menu-active-link;

        text-decoration:none;
      }

      i {
        @extend .pr;

        font-size:16px;
        line-height:1;
        color:$color-link;
        font-style:normal;
        vertical-align:bottom;

        margin:0 0 5px 12px;

        &:after {
          content:'';
          display:block;
          height:2px;
          width:100%;
          position:absolute;
          top:50%;
          left:0;
          margin:-1px 0 0;
          background:#00af67;
        }
      }
    }

    &-actions {
      margin-top:30px;
    }

    &-bottom-block {
      @extend .pr;

      margin:45px 0 0;

      display:flex;
      flex-direction:row;
    }

    &-banner {

      &-wrapper {
        @extend .pr;

        margin:26px 0 0 40px;

        max-width:579px;
      }

      background:#f2fef5;
      color:$menu-active-link;

      padding:20px 94px 20px 47px;

      font-size:16px;
      line-height:22px;

      p:first-child b,
      p:first-child strong {
        @extend .pr;

        &:before {
          content:'';

          display:block;
          width:21px;
          height:16px;

          position:absolute;
          top:0;
          left:0;

          margin:-1px 0 0 -26px;

          background:url(imgs/i-horn.png) 0 0 no-repeat;
        }
      }
    }

    &-tabs {
      flex:1;
      //max-width:560px;

      &__tabs {
        font-size:0;
        line-height:0;

        padding:0 0 10px;

        > a {
          display:inline-block;
          vertical-align:top;

          font-size:16px;
          line-height:1;
          font-weight:700;

          margin:0 20px;

          &:first-of-type {
            margin-left:0;
          }

          &.active {
            color:$menu-active-link;
            cursor:default;
          }
        }
      }

      &__content {

        border-top:2px solid #e9f1f3;

        &-item {
          display:none;

          padding:14px 0 16px;

          border-bottom:2px solid #e9f1f3;

          p {
            margin:0;
          }
        }
      }
    }
  }
}

@import 'product-zmedia';
