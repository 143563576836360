@import 'variables';

.actions-list {
  font-size:0;
  line-height:0;

  margin:23px -10px 30px;

  &__item {
    @extend .pr;
    box-shadow:0 8px 21px rgba(66, 112, 131, 0.3);
    transition:box-shadow .4s;

    display:inline-block;
    vertical-align:text-top;

    padding:20px;

    width:380px;
    height:195px;
    margin:0 10px 18px;

    text-align:left;

    background:#fff no-repeat bottom right;
    background-size:auto 100%;

    > * {
      max-width:180px;
    }

    &__footer {
      position:absolute;
      bottom:0;
      left:0;

      margin:0 0 20px 20px;

      width:100%;
    }

    &-title {
      font-size:16px;
      line-height:22px;
      font-weight:700;

      display:block;
    }

    &-action-text {
      font-size:16px;
      line-height:22px;
      font-weight:700;

      color:$menu-active-link;

      display:block;

      u {
        display:block;

        font-size:22px;
        line-height:1;

        text-decoration:none;
      }
    }

    &-action-price {
      font-size:22px;
      line-height:1;
      font-weight:700;

      color:$menu-active-link;

      display:block;

      &_old {
        @extend .pr;

        font-size:16px;
        line-height:1;
        font-weight:700;

        color:$color-link;

        display:inline-block;
        vertical-align:top;

        margin-bottom:10px;

        &:after {
          content:'';

          display:block;
          height:2px;
          width:100%;

          position:absolute;
          top:50%;
          left:0;

          margin:-1px 0 0;
          background:$menu-active-link;
        }
      }
    }

    &-shop {
      display:block;

      font-size:16px;
      line-height:1;
      color:#8fa8b4;

      overflow:hidden;
      text-overflow:ellipsis;
    }

    &:hover {
      box-shadow:0 8px 21px rgba(66, 112, 131, 0.5);

      .actions-list__item-title {
        color:$color-link;
      }
    }
  }
}

@import 'actions-zmedia';
