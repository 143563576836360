@import 'variables';

@media all and (max-width:$sm-width) {

  .wide-banners {

    &__item {

      a {
        /*height:308px;*/

        padding:45px $xs-padding 0;
        margin:0 10px;
      }

      &-title {
        font-size:24px;
        line-height:26px;

        max-width:none;
      }

      &-subtitle {
        font-size:14px;
        line-height:20px;

        margin-top:8px;
      }
    }
  }
}
