@import 'variables';

@media all and (max-width:$sm-width) {

  .content-wrapper,
  .layout-shop-product .content-wrapper,
  .layout-blog .content-wrapper {
    padding-top:30px;

    padding-left:$xs-padding;
    padding-right:$xs-padding;
  }

  .layout-shop-product .content-wrapper,
  .layout-shop-item .content-wrapper,
  .layout-sales .content-wrapper,
  .layout-blog .content-wrapper,
  .layout-faq .content-wrapper,
  .layout-reviews .content-wrapper {
    padding-bottom:270px;
  }

  .layout-shop .content-wrapper {
    padding-bottom:210px;
  }

  .layout-cart .content-wrapper,
  .layout-sales-item .content-wrapper,
  .layout-prices-item .content-wrapper,
  .layout-blog-item .content-wrapper {
    padding-left:$xs-padding;
    padding-right:$xs-padding;

    padding-bottom:250px;
  }
}