@import 'variables';

@media all and (max-width:$sm-width) {

  .products-list {
    text-align:center;

    &__item {

      &-title {
        font-size:14px;
        line-height:20px;
      }

      &-price {
        font-size:18px;

        &_old {
          font-size:14px;

          margin-bottom:3px;
        }
      }
    }
  }
}