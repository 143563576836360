@import 'variables';

.common-content {
  max-width:780px;

  margin-top:24px;
}

.content {
  font-size:16px;
  line-height:22px;
  color:$color-link;

  .video {
    font-size:0;
    line-height:0;
  }

  blockquote {
    @extend .pr;

    background:#f2fef5;
    color:$menu-active-link;

    padding:30px 100px 34px 60px;
    margin:28px 0 23px;

    h2, h1 {
      @extend .pr;

      margin-top:0;
    }

    h2 {
      &:before {
        content:'';

        display:block;
        width:17px;
        height:17px;

        position:absolute;
        top:0;
        left:0;

        margin:2px 0 0 -28px;

        background:url(imgs/i-attention-1.png) 0 0 no-repeat;
      }
    }

    p {
      margin-bottom:0;
    }
  }

  img {
    max-width:100%;
    width:auto;
    height:auto;
  }

  h1 {
    font-size:32px;
    line-height:1;
    font-weight:700;

    margin-top:36px;
  }

  h2 {
    font-size:22px;
    line-height:26px;
    font-weight:700;

    margin:34px 0 12px;
  }

  > h2:first-child {
    margin-top:0;
  }

  p {
    margin:0 0 20px;

    &.text-muted {
      margin-top:24px;
    }
  }

  a {
    text-decoration:underline;
  }

  ul, ol {
    margin-bottom:20px;

    li {
      margin:0 0 10px;
    }
  }

  ul {

    > li {

      padding-left:30px;

      background:url(imgs/ul-marker.svg) 0 6px no-repeat;
      -webkit-background-size:9px;
      background-size:9px;
    }
  }

  ol {
    counter-reset:my-badass-counter;

    > li {
      @extend .pr;

      padding-left:30px;

      &:before {
        content:counter(my-badass-counter) '.';
        counter-increment:my-badass-counter;

        font-size:16px;
        line-height:1;
        font-weight:700;
        color:#22c3aa;

        /*background-image:linear-gradient(-233deg, #68de84 0%, #13bdb3 49%, #097c96 100%);
        -webkit-background-clip:text;
        -moz-background-clip:text;
        background-clip:text;*/

        position:absolute;
        top:3px;
        left:0;
      }
    }
  }

  .content-img-plate {
    @extend .pr, .ovh;

    height:413px;

    img {
      position:absolute;

      &:nth-child(1) {
        top:0;
        left:0;

        width:280px;
      }
      &:nth-child(2) {
        top:0;
        left:0;

        width:180px;

        margin-left:302px;
      }
      &:nth-child(3) {
        top:0;
        right:0;

        width:280px;
        height:100%;
      }
      &:nth-child(4) {
        bottom:0;

        width:180px;
      }
      &:nth-child(5) {
        bottom:0;

        width:280px;

        margin-left:202px;
      }
    }
  }

  .content-links-table {
    max-width:580px;

    font-size:0;
    line-height:0;

    > * {
      @extend .pr;

      display:inline-block;
      vertical-align:top;

      width:33.3%;
      height:124px;

      text-align:center;
      line-height:124px;
      text-decoration:none;

      &:nth-child(1), &:nth-child(4) {

        &:after {
          content:'';

          position:absolute;
          top:8px;
          bottom:8px;
          right:0;

          width:2px;

          background-color:#e9f1f3;
        }
      }

      &:nth-child(1), &:nth-child(2), &:nth-child(3) {

        &:before {
          content:'';

          position:absolute;
          bottom:0;
          left:8px;
          right:8px;

          height:2px;

          background:#e9f1f3;
        }
      }

      &:nth-child(3), &:nth-child(6) {

        &:after {
          content:'';

          position:absolute;
          top:8px;
          bottom:8px;
          left:0;

          width:2px;

          background-color:#e9f1f3;
        }
      }
    }
  }
}

@import 'content-zmedia';
