@import 'variables';

.b-reviews-list {
  margin:0 0 80px;

  font-size:0;
  line-height:0;
  white-space:nowrap;

  &__col {
    display:inline-block;
    vertical-align:top;

    width:33.3%;
    max-width:385px;

    &:nth-child(2) {
      margin:0 12px;
    }
  }

  &__item {
    font-size:16px;
    line-height:22px;
    color:$color-link;
    white-space:normal;
    text-align:left;

    margin:0 0 45px;

    &-content {
      @extend .pr;

      padding:23px 27px 30px;

      background:#fff;
      border:2px solid #e9f1f3;

      &:before {
        position:absolute;
        bottom:0;
        left:0;

        width:12px;
        height:8px;

        content:'';
        margin:0 0 -8px 32px;

        background:url(imgs/review-tr.png) 0 0 no-repeat;
      }
    }

    &-body {
      padding:0 0 30px;
    }

    &-date {
      font-size:14px;
      line-height:1;
      font-weight:400;
      color:#8fa8b4;
    }

    .rating {
      position:absolute;
      bottom:0;
      right:0;

      margin:0 30px 30px;
    }

    &-author {
      font-size:16px;
      line-height:22.61px;
      font-weight:700;

      margin:15px 0 0;
    }
  }
}

@import 'reviews-zmedia';
