@import 'variables';

@media all and (max-width:$sm-width) {

  .pagination {
    text-align:center;

    &-wrapper {

      .btn {
        display:block;

        width:100%;
        padding-left:0;
        padding-right:0;

        margin:0 0 20px;
      }
    }

    li {
      font-size:14px;
      line-height:38px;

      a, span, &.arrow {
        width:36px;
        height:36px;

        line-height:38px;
      }

      &.arrow {
      }
    }
  }
}