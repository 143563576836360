@import 'variables';

@media all and (max-width:$sm-width) {

  .css-mobile-dropdown-menu {
    margin-bottom:30px;

    &__content {
      display:none;
      overflow:hidden;

      box-shadow:none;
      border:1px solid #e9f1f3;
      border-top:none;

      ul {

        li {
          font-size:14px;
          line-height:1;

          a, span {
            padding:13px 18px 11px;
          }
        }
      }
    }

    &__title {
      position:relative;

      display:block;
      padding:13px 18px 11px;
      margin:0;

      font-size:14px;
      line-height:1;
      font-weight:700;
      text-decoration:none;

      color:$color-link;

      border:1px solid #e9f1f3;

      &:after {
        content:'';

        display:block;
        width:15px;
        height:9px;

        position:absolute;
        top:50%;
        right:0;
        margin:-4px 20px 0 0;

        background:url(imgs/faq-arrow.png) top center no-repeat;
      }
    }

    &__tabs {
      //display:table;
      width:100%;

      font-size:0;
      line-height:0;
      white-space:nowrap;

      border-collapse:collapse;

      .row {
        display:table-row;
      }

      a {
        display:table-cell;
        vertical-align:top;

        padding:13px 18px 11px;
        margin:0;

        font-size:14px;
        line-height:1;
        font-weight:700;
        text-decoration:none;

        color:$color-link;

        border:1px solid #e9f1f3;

        &.active {
          border-bottom:none;
          color:$color-hover;
        }
      }
    }

    &.opened {

      .css-mobile-dropdown-menu {

        &__content {
        }

        &__title {
          border-bottom-color:transparent;

          &:after {
            background-position:bottom center;
          }
        }
      }
    }
  }

  .shop-view,
  .page-sales {

    .css-mobile-dropdown-menu {
      margin-bottom:20px;

      &__title {
        display:block;
      }

      &__tabs {
        display:table;
      }

      &__content {

        padding-bottom:10px;

        .categories {

          &-actions {

            text-align:right;

            padding-right:20px;
            margin-top:10px;

            s, s + a {
              display:none;
            }
          }

          &-list {
            padding:20px 20px 10px;

            a {
              margin-bottom:10px;

              display:inline-block !important;
            }
          }
        }
      }
    }
  }
}
