@import 'variables';

.swiper-pagination {

  &-bullet {
    width:8px;
    height:8px;

    margin:0 5px;

    background:#d5dfe2;
    text-decoration:none;

    &-active,
    &:hover {
      background:#1a5e78;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  width:14px;
  height:43px;

  margin-top:-23px;

  outline:none;
}

.swiper-button-prev {
  left:0;

  background:url(imgs/slider-left.png) 0 0 no-repeat;

  &:hover {
    background-position:0 -43px;
  }
}

.swiper-button-next {
  right:0;

  background:url(imgs/slider-right.png) 0 0 no-repeat;

  &:hover {
    background-position:0 -43px;
  }
}

@import 'swipe-slider-zmedia';
