@import 'variables';

.index-top-banners {
  @extend .pr;

  &__text {
    position:absolute;
    top:50%;
    left:0;

    z-index:1;

    width:430px;
    margin-top:-110px;

    font-weight:700;
    color:#fff;

    text-align:left;
  }

  h2 {
    font-size:48px;
    line-height:58px;
    text-transform:uppercase;

    letter-spacing:.19px;
  }

  h3 {
    font-size:32px;
    line-height:38px;
  }

  &__products {

    .products-list {
      @extend .pr;

      margin:0;
      height:414px;

      &__item {
        position:absolute;
        margin:0;

        &:nth-child(1) {
          top:0;
          right:0;

          margin-right:302px;

          width:383px;
        }

        &:nth-child(2) {
          top:0;
          right:0;

          width:282px;
        }

        &:nth-child(3) {
          right:0;
          bottom:0;

          margin-right:401px;

          width:286px;
        }

        &:nth-child(4) {
          right:0;
          bottom:0;

          width:384px;
        }
      }
    }
  }
}

@import 'index-top-banners-zmedia';
