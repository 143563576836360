@import 'variables';

@media all and (max-width:$sm-width) {

  .blog {

    &-list {

      &__item {
        display:block;

        width:auto;
        height:auto;

        margin-left:auto;
        margin-right:auto;

        //max-width:none;
        max-height:none;
        min-height:0;

        &-date {
          font-size:12px;
          line-height:1;
        }

        &-title {
          font-size:18px;
          line-height:22px;
        }

        &-lead {
          font-size:14px;
          line-height:20px;
        }
      }
    }

    &-categories {

      margin:0;

      &-wrapper {

        .css-mobile-dropdown-menu__title {
          display:block;
        }
      }

      ul {
        position:relative;
        overflow:hidden;
        z-index:1;

        li {
          position:relative;

          display:block;
          margin:0;

          font-size:14px;
          line-height:1;
          font-weight:700;

          &:after {
            content:'';

            position:absolute;
            bottom:0;
            left:20px;
            right:20px;

            display:block;
            height:2px;

            background:#e9f1f3;
          }

          a, span {
            display:block;
            vertical-align:top;

            padding:13px 18px 11px;
          }

          &.active, &:hover {

            &, a {
              color:#00af67;
            }

            &:before {
              content:'';

              display:block;
              width:100%;

              position:absolute;
              top:-2px;
              bottom:0;
              left:0;

              z-index:-1;

              background:#d7fde0;

              pointer-events:none;
            }
          }

          &:last-of-type {
            &:after {
              content:none;
            }
          }
        }
      }
    }
  }
}