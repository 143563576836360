@import 'variables';

@include font-face(Muller, MullerRegular, 400, normal, U+0020-25CA);
@include font-face(Muller, MullerBold, 700, normal, U+0020-25CA);

@import 'reset';
@import 'common';
@import 'icons';
@import 'mobile-menu';

@import 'layouts';
@import 'header';
@import 'footer';

@import 'pagination';
@import 'breadcrumbs';
@import 'content';

@import 'lightboxes';

@import 'faq';
@import 'reviews';
@import 'categories';
@import 'actions';
@import 'products';
@import 'index-page';
@import 'advantages';
@import 'index-top-banners';
@import 'payments';
@import 'wide-banners';
@import 'swipe-slider';
@import 'calc';
@import 'infoblock';
@import 'range-slider';
@import 'prices';
@import 'table-scroll';
@import 'delivery-view';
@import 'sale-view';
@import 'shops';
@import 'shop-view';
@import 'catalog-filters';
@import 'cart';
@import 'product';
@import 'blog';

@import 'dropdown-mobile-zmedia';