@import 'variables';

.table-scroll {

  user-select:none;

  position:absolute;
  top:0;
  left:0;
  right:0;

  margin:2px 0 0;

  z-index:10;

  &.bottom {
    margin-top:0;
    margin-bottom:2px;

    top:auto;
    bottom:0;

    .table-scroll__line {
      border-bottom:none;
      border-top:2px solid #fff;
    }
  }

  &__line {
    @extend .pr;

    font-size:0;
    line-height:0;

    width:100%;
    height:6px;

    border-bottom:2px solid #fff;

    box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
    background:#e9f1f3;

    &-select {
      display:block;

      position:absolute;
      top:0;
      left:0;

      height:4px;

      background:#ebf1f4 linear-gradient(to right, #16beb1 0%, #16beb1 49%, #51d591 100%);
      box-shadow:inset 0 -1px 3px rgba(23, 69, 88, 0.22);
    }

    &-handler {
      display:block;
      width:22px;
      height:23px;

      cursor:pointer;

      position:absolute;
      top:0;
      right:0;

      margin:-8px -11px 0 0;

      background:url(imgs/range-handler.png) 0 0 no-repeat;

      &:hover {
        background-position:bottom left;
      }
    }
  }
}
