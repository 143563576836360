@import 'variables';

.prices-table {
  @extend .pr;

  &-main {
    @extend .pr;

    width:auto !important;
    height:auto !important;
    min-width:100%;

    font-size:14px;
    line-height:18px;
    color:$color-link;

    &.scroll {

      td:first-of-type {
        opacity:0;
      }
    }

    td {
      border:2px solid #fff;

      padding:20px;

      &:nth-child(2) {
        box-shadow:inset 5px 0 rgba(26, 94, 120, .06);
      }
    }

    thead, tbody {

      tr {
        height:auto !important;
      }
    }

    thead {

      td {
        height:auto !important;
        width:auto !important;
        background:#dcf3f6;
      }
    }

    tbody {

      td {
        background:#f5f8f9;

        &:first-of-type {
          font-weight:700;
        }
      }

      tr:nth-child(odd) {
        td {
          background:#e9f1f3;
        }
      }

      tr.hover {
        td {
          background:#ebfeef;
          color:$menu-active-link;
          cursor:pointer;
        }
      }
    }
  }

  &-fixed {
    @extend .prices-table-main;

    position:absolute;
    top:0;
    left:0;

    min-width:0;
    margin:0;
  }

  &__main {

    &__table {
      @extend .ovh, .pr;

      &:after {
        content:'';

        width:28px;
        height:100%;

        display:block;
        position:absolute;
        top:0;
        right:0;

        pointer-events:none;

        background:url(imgs/prices-table-shadow.png) 0 0 repeat-y;
      }
    }

    &-wrapper {
      @extend .pr;

      margin-bottom:25px;
    }
  }
}

.prices-info {
  margin-top:50px;
  max-width:515px;

  font-size:16px;
  line-height:22px;
  color:$color-link;

  h2 {
    font-size:22px;
    line-height:1;
    color:$menu-active-link;
  }

  p {
    margin:10px 0 25px;
  }
}

@import 'prices-zmedia';
