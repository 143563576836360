@import 'variables';

@media all and (max-width:$sm-width) {

  .product {

    &__photos {
      display:none;
    }

    &__info {
      display:flex;
      flex-direction:column;

      max-width:none;
      margin-left:0;

      &-mobile-photos {
        display:block;

        padding:0 20px 30px;
      }

      &-title {
        font-size:24px;
        line-height:26px;
        text-align:center;
      }

      &-rating {

        .rating {
          margin:0 auto;
        }
      }

      &-buy-wrapper {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
      }

      &-price {
        display:inline-block;
        vertical-align:top;

        margin:0;

        span {
          font-size:14px;
        }

        u {
          font-size:24px;
        }

        i {
          font-size:14px;

          margin:0 0 2px 5px;
        }
      }

      &-lead {
        order:1;

        margin-top:25px;
      }

      &-actions {
        margin:0;

        .btn {
          padding-left:25px;
          padding-right:25px;
        }
      }

      &-bottom-block {
        flex-direction:column;

        margin-top:28px;
      }

      &-tabs {
        //display:none;
        flex:none;

        &__tabs {

          > a {
            display:block;

            margin:0;
            padding:12px 0;

            border-bottom:2px solid #e9f1f3;

            position:relative;

            &:first-of-type {
              border-top:2px solid #e9f1f3;
            }

            &:after {
              content:'';

              display:block;
              width:15px;
              height:9px;

              position:absolute;
              top:50%;
              right:0;

              margin:-5px 0 0;

              background:url(imgs/faq-arrow.png) 0 0 no-repeat;
            }

            &.active {

              &:after {
                background-position:0 -18px;
              }
            }
          }

          .product__info-tabs__content-item {
          }
        }

        &__content {
          display:none;
        }
      }

      &-banner {
        padding:20px 34px 20px 34px;

        text-align:center;

        &-wrapper {
          max-width:none;
          margin:40px auto 0;

          width:100%;
        }
      }
    }

    &-widget {
      text-align:center;

      margin-left:-$xs-padding;
      margin-right:-$xs-padding;

      h3 {
        font-size:18px;
        line-height:22px;
      }

      .swiper-container {
        padding:0 20px 30px;
      }

      .actions-list {
        max-width:none;

        white-space:nowrap;

        &__item {
          display:inline-block;
          vertical-align:top;

          width:280px;
          margin:0;
        }
      }

      .products-list {
        margin-left:0;
        margin-right:0;

        &__item {
          margin:0;
        }
      }

      &.similar-products {
      }
    }
  }
}
