@import 'variables';

.icon {
  display:inline-block;
  vertical-align:top;

  font-size:0;
  line-height:0;
  font-style:normal;

  &-close {
    width:20px;
    height:20px;

    background:url(imgs/i-close.png) 0 0 no-repeat;
  }

  &-login {
    width:15px;
    height:16px;

    background:url(imgs/i-login.png) 0 0 no-repeat;
  }

  &-reg {
    width:15px;
    height:16px;

    background:url(imgs/i-reg.png) 0 0 no-repeat;
  }

  &-erase {
    width:14px;
    height:12px;

    background:url(imgs/i-erase.png) 0 0 no-repeat;
  }

  &-arrow-gray {
    width:12px;
    height:8px;

    background:url(imgs/arrow-gray.png) 0 0 no-repeat;
  }

  &-arrow {
    width:15px;
    height:9px;

    background:url(imgs/faq-arrow.png) 0 0 no-repeat;
  }

  &-arrow-active {
    background-position:0 -18px;
  }

  &-play {
    width:70px;
    height:70px;

    background:url(imgs/video-play.png) 0 0 no-repeat;
  }

  &-calendar {
    width:70px;
    height:78px;

    background:url(imgs/i-calendar.png) 0 0 no-repeat;
  }

  &-box {
    width:75px;
    height:87px;

    background:url(imgs/i-box.png) 0 0 no-repeat;
  }

  &-n1 {
    width:40px;
    height:40px;

    background:url(imgs/i-n1.png) 0 0 no-repeat;
  }

  &-n2 {
    width:40px;
    height:40px;

    background:url(imgs/i-n2.png) 0 0 no-repeat;
  }

  &-n3 {
    width:40px;
    height:40px;

    background:url(imgs/i-n3.png) 0 0 no-repeat;
  }

  &[class*="-preset-"] {
    height:26px;
  }

  a.active &[class*="-preset-"],
  a:hover &[class*="-preset-"] {
    background-position:bottom left;
  }

  &-preset-100 {
    width:28px;

    background:url(imgs/preset-100.png) 0 0 no-repeat;
  }

  &-preset-150 {
    width:29px;

    background:url(imgs/preset-150.png) 0 0 no-repeat;
  }

  &-preset-200 {
    width:18px;

    background:url(imgs/preset-200.png) 0 0 no-repeat;
  }

  &-preset-400 {
    width:26px;

    background:url(imgs/preset-400.png) 0 0 no-repeat;
  }

  &-preset-500 {
    width:17px;

    background:url(imgs/preset-500.png) 0 0 no-repeat;
  }

  &-preset-800 {
    width:30px;

    background:url(imgs/preset-800.png) 0 0 no-repeat;
  }

  &-preset-900 {
    width:42px;

    background:url(imgs/preset-900.png) 0 0 no-repeat;
  }

  &-preset-2500 {
    width:42px;

    background:url(imgs/preset-2500.png) 0 0 no-repeat;
  }

  &-important {
    width:42px;
    height:42px;

    background:url(imgs/i-important.png) 0 0 no-repeat;
  }

  &-checkbox {
    @extend .pr;

    width:24px;
    height:24px;

    box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
    background:#e9f1f3;

    &:after {
      content:'';

      display:none;
      width:13px;
      height:11px;

      position:absolute;
      top:50%;
      left:50%;

      margin:-6px 0 0 -7px;

      background:url(imgs/i-check.png) 0 0 no-repeat;
    }
  }

  &-back, &-forward {
    width:8px;
    height:12px;

    background:url(imgs/arrow-back.png) 0 0 no-repeat;
  }

  &-forward {
    background:url(imgs/arrow-forward.png) 0 0 no-repeat;
  }

  &-social {
    width:43px;
    height:43px;

    &-inst {
      background:url(imgs/s-inst.png) 0 0 no-repeat;
    }

    &-vk {
      background-image:url(imgs/s-vk.png);
    }

    &-fb {
      background-image:url(imgs/s-fb.png);
    }

    &-ok {
      background-image:url(imgs/s-ok.png);
    }

    &-tw {
      background-image:url(imgs/s-tw.png);
    }

    &:hover {
      background-position:bottom left;
    }
  }

  &-weight {
    width:60px;
    height:72px;

    background:url(imgs/i-weight.png) 0 0 no-repeat;
  }

  &-delivery {
    width:75px;
    height:71px;

    background:url(imgs/i-delivery.png) 0 0 no-repeat;
  }

  &-sizes {
    width:72px;
    height:72px;

    background:url(imgs/i-sizes.png) 0 0 no-repeat;
  }

  &-protect {
    width:15px;
    height:16px;

    background:url(imgs/i-protect.png) 0 0 no-repeat;
  }

  &-extend {
    width:15px;
    height:15px;

    background:url(imgs/i-extend.png) 0 0 no-repeat;
  }

  &-attention {
    width:15px;
    height:15px;

    background:url(imgs/i-attention.png) 0 0 no-repeat;
  }

  &-hand {
    width:13px;
    height:16px;

    background:url(imgs/i-hand.png) 0 0 no-repeat;
  }

  &-remove {
    width:16px;
    height:16px;

    background:url(imgs/i-remove.png) 0 0 no-repeat;

    &:hover {
      background-position:bottom left;
    }
  }

  &-cart {
    width:22px;
    height:16px;

    background:url(imgs/i-cart.png) 0 0 no-repeat;
  }
}

a:hover {

  .icon {

    &-cart, &-login, &-reg, &-hand, &-protect, &-attention, &-extend, &-erase, &-back, &-forward {
      background-position:bottom left;
    }

    &-arrow-gray {
      background-position:0 -16px;
    }
  }
}

.link_icon {

  &-cart, &-login, &-reg, &-hand, &-protect, &-attention, &-extend, &-erase, &-back, &-forward {

    &:hover {

      &:before {
        background-position:bottom left;
      }
    }
  }
}

/*******************************************************************/

label:hover {

  [type="checkbox"] {

    & + .icon-checkbox {
      box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
      background-color:#f4f8f9;
    }
  }
}

[type="checkbox"] {
  position:fixed;
  top:-6000px;
  left:-6000px;

  opacity:.0001;
  width:1px;
  height:1px;

  &:checked + .icon-checkbox {
    box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
    background:#e9f1f3;

    &:after {
      display:block;
    }
  }
}
