@import 'variables';

@media all and (max-width:$sm-width) {

  .shop-view {

    .css-mobile-dropdown-menu {

      .categories-menu {
      }

      &__content {

        .catalog-filters {
          padding-top:10px;
          padding-left:20px;
          padding-right:20px;

          white-space:normal;

          fieldset:last-of-type {
            display:block;
            margin-left:0;

            text-align:left;
          }
        }
      }
    }

    &__col {

      &-left, &-right {
        float:none;

        width:auto;
        max-width:none;

        margin-left:0;
      }

      &-left {
        .categories-menu {
        }
      }

      &-right {
        .catalog-filters {
          display:none;
        }
      }
    }
  }

  .layout-shop-item {

    .link2back {
      float:none;

      margin-top:0;
    }
  }
}