$color-link:#1a5e78;
/*$color-hover:#2fb4e9;*/
$color-hover:#00af67;
$color-placeholder:#8fa8b4;

$top-footer-color:#e9f1f3;
$menu-active-link:#00af67;
$menu-active-link-transparent:rgba(0, 175, 103, .7);

$lg-width:1110px;
$md-width:990px;
$sm-width:768px;
$xs-width:590px;

$xs-padding:20px;

@mixin font-face($name, $path, $fw, $fs, $charset: false) {
  @font-face {
    font-family:$name;

    src:url('/media/css/fonts/#{$path}/#{$path}.eot');
    src:url('/media/css/fonts/#{$path}/#{$path}.eot?#iefix') format('embedded-opentype'),
    url('/media/css/fonts/#{$path}/#{$path}.woff2') format('woff2'),
    url('/media/css/fonts/#{$path}/#{$path}.woff') format('woff'),
    url('/media/css/fonts/#{$path}/#{$path}.ttf') format('truetype');
    font-stretch:normal;
    font-weight:$fw;
    font-style:$fs;

    @if $charset {
      unicode-range:$charset;
    }
  }
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    color:$color;
  }
  &::-moz-placeholder {
    color:$color;
  }
  &:-moz-placeholder {
    color:$color;
  }
  &:-ms-input-placeholder {
    color:$color;
  }
}
