@import 'variables';

@media all and (max-width:$sm-width) {

  .footer {
    padding-left:$xs-padding;
    padding-right:$xs-padding;

    max-width:320px;

    font-size:0;

    &-wrapper {
      padding-top:20px;

      height:190px;
    }

    &-logo,
    &-menu,
    &-contacts > .btn,
    &-info p {
      display:none;
    }

    &-col {
      float:none;
      display:block;
      width:auto;
    }

    &-contacts,
    &-info {
      display:inline-block;
      vertical-align:top;

      width:50%;
    }

    &-info {
      padding:0;

      text-align:right;

      img {
        float:none;
      }
    }

    &-socials {

      .icon {
        width:36px;
        height:36px;

        -webkit-background-size:cover;
        background-size:cover;
      }
    }

    &-search {
      display:block;

      margin:20px 0 0;
      width:100%;

      &__wrapper {

        input[type="text"] {
          font-size:14px;

          padding-left:15px;
          padding-right:25px;

          height:36px;

          border-top-right-radius:0;
          border-bottom-right-radius:0;
        }

        button {
          padding-left:15px;
          padding-right:15px;

          min-width:0;
        }
      }
    }

    &-copy {
      position:relative;

      bottom:0;

      max-width:250px;
      margin:20px auto 0;

      font-size:12px;
      line-height:16px;
      text-align:center;
    }
  }
}
