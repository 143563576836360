@import 'variables';

@media all and (max-width:$sm-width) {

  .show-on-sm {
    display:block;
  }

  .btn {
    font-size:14px;
    line-height:36px;

    height:36px;
  }

  .g-page-title {
    font-size:24px;
    line-height:26px;
    text-align:center;
  }

  .text-muted {
    font-size:12px;
    line-height:18px;
  }

  .link2back {
    text-align:center;

    font-size:14px;

    margin:0 0 25px;

    .icon {
      margin-left:-16px;
    }

    &.reverse {
      text-align:center;
    }
  }

  .socials-share {
    white-space:normal;

    span {
      display:block;

      font-size:14px;
      line-height:1;

      margin:0 0 8px;
    }

    .icon {
      width:36px;
      height:36px;

      -webkit-background-size:cover;
      background-size:cover;

      &:first-of-type {
        margin-left:0;
      }
    }
  }

  form {

    .inline {

      label {

        .label- {
          font-size:14px;
        }
      }
    }
  }
}
