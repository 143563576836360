@import 'variables';

.footer {
  @extend .pr, %clearfix;

  font-size:14px;
  line-height:18px;
  color:$color-link;

  padding:0;

  &-col {

    &_left {
      @extend .fll;

      width:605px;
    }

    &_right {
      @extend .flr;

      width:445px;
    }
  }

  &-wrapper {
    position:absolute;
    bottom:0;
    left:0;

    display:block;
    width:100%;
    height:220px;

    background:$top-footer-color;

    padding-top:28px;
  }

  &-info {
    @extend %clearfix;

    padding:30px 0 0 0;

    p {
      @extend .fll;

      width:260px;
    }

    img {
      @extend .flr;

      margin:4px 3px 0 0;
    }
  }

  &-copy {
    position:absolute;
    bottom:8px;
    right:0;

    max-width:445px;
  }

  &-contacts {
    @extend %clearfix;

    > .btn {
      @extend .flr;

      padding:0;
      min-width:234px;
    }
  }

  &-socials {
    @extend .fll;

    font-size:0;
    line-height:0;

    .icon {
      margin:0 5px;

      &:first-of-type {
        margin-left:0;
      }
    }
  }

  &-logo {
    @extend .fll;

    margin:-4px 0 0 0;
  }

  &-search {
    margin:0 0 0 143px;
    width:447px;

    &__wrapper {
      @extend .pr;

      input[type="text"] {
        box-shadow:inset 0 1px 3px rgba(26, 94, 120, 0.22);
        border-radius:22px 0 0 22px;

        background:#fff;

        width:72%;
        height:41px;
        padding:1px 50px 0 20px;
        margin:0;

        font-size:16px;
        line-height:44px;
      }

      button {
        position:absolute;
        top:0;
        right:0;

        min-width:148px;

        padding:0 36px;
      }
    }

    &__success {
      display:none;
      opacity:0;

      font-size:16px;
      line-height:44px;

      transition:opacity .2s;

      &.showed {
        display:block;
        opacity:1;
      }
    }
  }

  &-menu {
    margin:19px 0 0 3px;

    font-size:0;
    line-height:0;

    ul {
      display:inline-block;
      vertical-align:top;

      margin-left:58px;

      font-size:14px;
      line-height:32px;

      &:first-of-type {
        margin-left:0;
      }
    }
  }
}

@import 'footer-zmedia';
