@import 'variables';

.body-wrapper, .main-wrapper {
  @extend .pr;

  min-height:100%;
  height:auto;
}

.body-wrapper {
  overflow:hidden;
  min-width:320px;
}

.header, .footer, .g-wrapper {
  @extend .pr;

  max-width:1180px;
  margin:0 auto;
}

.content-wrapper {
  padding:60px 0 455px;
}

.layout-reviews {

  .content-wrapper {
    padding-bottom:360px;
  }
}

.layout-index {

  .content-wrapper {
    padding:21px 0 230px;
  }
}

.layout-prices {

  .content-wrapper {
    padding-bottom:300px;
  }
}

.layout-shop-product {

  .content-wrapper {
    padding-top:66px;
    padding-bottom:325px;
  }
}

.layout-blog {

  .content-wrapper {
    padding-top:64px;
    padding-bottom:395px;
  }
}

.layout-cart {

  .content-wrapper {
    padding-left:110px;
    padding-bottom:400px;
  }
}

.layout-blog-item,
.layout-sales-item {

  .content-wrapper {
    padding-left:110px;
    padding-bottom:350px;
  }
}

.layout-prices-item {

  .content-wrapper {
    padding-left:110px;
    padding-top:28px;
    padding-bottom:350px;
  }
}

.layout-shop-item {

  .content-wrapper {
    padding-bottom:405px;
  }
}

@import 'layouts-zmedia';
