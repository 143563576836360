@import 'variables';

.sale-view {
  @extend .pr;

  margin:0 auto;
  max-width:880px;

  .g-page-title {
    margin-top:36px;
    margin-bottom:0;
  }

  .common-content {
    margin-bottom:46px;
  }
}
