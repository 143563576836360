@import 'variables';

@media all and (max-width:$sm-width) {

  .infoblock {
    height:auto;

    &__text {
      text-align:center;

      padding-left:$xs-padding;
      padding-right:$xs-padding;
      padding-bottom:20px;

      display:block;

      &-content {
        display:block;
        margin:10px 0 20px;

        max-width:none;

        text-align:center;

        br {
          display:none;
        }
      }

      .btn {
        padding-left:0;
        padding-right:0;

        width:100%;
      }
    }
  }
}
