@import 'variables';

.delivery-view {
  @extend .pr;

  margin:0 auto;
  max-width:880px;

  .g-page-title {
    margin-top:36px;
    margin-bottom:0;
  }

  .common-content {
    max-width:none;
  }

  &__buttons {
    font-size:0;
    line-height:0;
    white-space:nowrap;

    margin-top:54px;

    .btn {
      padding-left:0;
      padding-right:0;

      width:49%;

      &:last-of-type {
        float:right;
      }
    }
  }

  &__info {
    @extend %clearfix;

    margin-top:30px;

    > img {
      @extend .fll;

      max-width:374px;
      height:auto;
    }

    &-item {
      @extend .pr, .ovh;

      min-height:72px;

      margin:0 0 40px 400px;
      padding-left:100px;

      > .icon, &:before {
        position:absolute;
        top:0;
        left:0;

        content:'';
      }

      &_weight:before {
        @extend .icon-weight;
      }

      &_delivery:before {
        @extend .icon-delivery;
      }

      &_sizes:before {
        @extend .icon-sizes;
      }

      h2 {
        margin:0 0 2px;

        line-height:1;
      }

      p {
        margin:0;
      }

      &:last-of-type {
        margin-bottom:0;
      }
    }
  }
}

.how-pay {
  font-size:0;
  line-height:0;

  margin:-7px 0 15px;

  &-item {
    @extend .pr;

    display:inline-block;
    width:30%;

    padding:0 0 10px;

    white-space:normal;
    vertical-align:top;

    &__title {
      font-size:22px;
      line-height:41px;
      font-weight:700;
      color:$color-link;

      &:before {
        content:'';

        @extend .icon;

        margin-right:7px;
      }
    }

    &__lead {
      font-size:16px;
      line-height:22px;
      color:$color-link;

      margin:10px 0 0;

      p {
        margin:0;
      }
    }

    &:nth-child(1) {
      .how-pay-item__title:before {
        @extend .icon-n1;
      }
    }

    &:nth-child(2) {

      margin-left:2%;

      .how-pay-item__title:before {
        @extend .icon-n2;
      }

      &:before, &:after {
        pointer-events:none;

        content:'';

        position:absolute;
        top:0;
        left:-36px;

        width:2px;
        height:100%;

        background-color:#e9f1f3;
      }

      &:after {
        left:auto;
        right:-11px;
      }
    }

    &:nth-child(3) {
      margin-left:6%;
      width:31%;

      .how-pay-item__title:before {
        @extend .icon-n3;
      }
    }
  }
}

@import 'delivery-view-zmedia';
