@import 'variables';

@media all and (max-width:$sm-width) {

  .index-top-banners {

    .swiper-container {
      padding:0 20px 30px;
    }

    &__text {
      position:relative;

      margin:30px 0 25px;
      padding-left:$xs-padding;
      padding-right:$xs-padding;

      width:auto;
      height:auto;

      text-align:center;
    }

    h2 {
      font-size:28px;
      line-height:34px;
    }

    h3 {
      font-size:24px;
      line-height:26px;
    }

    &__products {

      .products-list {
        height:auto;
        white-space:nowrap;

        &__item {

          &, &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            display:inline-block;
            vertical-align:top;

            position:relative;
            top:auto;
            left:auto;
            right:auto;
            bottom:auto;

            margin:0;

            width:280px;
            height:170px;

            white-space:normal;
          }
        }
      }
    }
  }
}
