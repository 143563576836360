@import 'variables';

.advantages {
  @extend %clearfix;

  max-width:996px;
  margin:0 auto;

  &__video {
    @extend .fll, .pr;

    display:block;

    width:472px;
    height:305px;

    margin-top:-3px;

    background:no-repeat center;
    -webkit-background-size:cover;
    background-size:cover;

    cursor:pointer;

    &:before {
      content:'';

      position:absolute;
      z-index:-1;

      top:0;
      left:0;
      right:0;
      bottom:0;

      margin:-5px;

      border:2px solid #e9f1f3;

      pointer-events:none;
    }

    .icon-play {
      position:absolute;
      top:50%;
      left:50%;

      margin:-46px 0 0 -35px;
      z-index:1;
    }

    &-title {
      font-size:18px;
      line-height:22px;
      font-weight:700;
      color:#feffff;

      position:absolute;
      z-index:1;
      top:50%;

      margin:36px 0 0;

      width:100%;
    }

    &-overlay {
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;

      z-index:1;

      background:linear-gradient(-143deg, #096a8c 0%, #16beb1 49%, #51d591 100%);
      opacity:.82;
    }

    &:hover {

      .advantages__video-overlay {
        background:linear-gradient(-143deg, #096a8c 0%, #0d8397 49%, #16bfb1 100%);
      }
    }
  }

  &__list {
    margin-left:525px;

    text-align:left;

    &-item {
      color:$color-link;

      margin-top:36px;

      &:first-of-type {
        margin-top:0;
      }

      &__content {
        margin-left:100px;
        max-width:355px;

        h3 {
          font-size:22px;
          line-height:26px;
        }

        p {
          font-size:16px;
          line-height:22px;

          margin-top:6px;
        }
      }

      &__icon {
        @extend .fll;

        display:block;

        width:69px;
        height:70px;

        &-1 {
          background:url(imgs/i-we-3.png) 0 0 no-repeat;

          margin:0 0 0 3px;
        }

        &-2 {
          background:url(imgs/i-we-2.png) 0 0 no-repeat;

          margin:1px 0 0 9px;
        }

        &-3 {
          background:url(imgs/i-we-1.png) 0 0 no-repeat;

          margin:1px 0 0 8px;
        }
      }
    }
  }
}

@import 'advantages-zmedia';
