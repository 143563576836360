@import 'variables';

.b-shop-list {

  @extend %clearfix;

  &__col {

    &-left {
      @extend .fll;

      width:255px;

      .css-mobile-dropdown-menu__title {
        display:none;
      }
    }

    &-right {
      margin-left:300px;
    }
  }

  &__item {
    @extend .pr;

    margin:0 0 50px;

    &-info {
      max-width:600px;
      color:$color-link;

      padding-right:60px;

      &__title {
        font-size:22px;
        line-height:1;
        font-weight:700;

        margin:0 0 17px;
      }

      &__lead {
        font-size:16px;
        line-height:22px;

        p {
        }

        margin:0 0 12px;
      }

      &__buttons {
      }
    }

    &-logo {
      position:absolute;
      top:0;
      right:0;

      display:block;
      width:280px;
      height:100%;

      background:no-repeat center;

      img {
        display:none;
      }

      &:before {
        content:'';

        position:absolute;
        top:0;
        left:0;

        width:2px;
        height:100%;

        background:#e9f1f3;
      }
    }

    &:hover {

      .b-shop-list__item-info__title {
        color:$menu-active-link;
      }
    }
  }
}

@import 'shops-zmedia';