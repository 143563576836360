@import 'variables';

@media all and (max-width:$sm-width) {

  .b-shop-list {

    &__col {

      &-left, &-right {
        float:none;

        width:auto;
        max-width:none;

        margin-left:0;
      }

      &-left {
        .css-mobile-dropdown-menu__title {
          display:block;
        }

        .categories-menu {
        }
      }

      &-right {
      }
    }

    &__item {
      text-align:center;

      &-info {
        padding:0;
        margin:0 auto;

        max-width:none;

        &__title {
          margin:20px 0 15px;

          font-size:18px;
          line-height:22px;
        }

        &__lead {
          font-size:14px;
          line-height:20px;

          p {
          }
        }

        &__buttons {
        }
      }

      &-logo {
        position:relative;

        width:100%;
        height:auto;

        img {
          display:inline-block;
          vertical-align:top;
        }

        &:before {
          display:none;
        }
      }
    }
  }
}