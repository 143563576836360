@import 'variables';

.catalog-filters {
  text-align:right;
  white-space:nowrap;

  fieldset {
    @extend .pr;

    line-height:42px;

    display:inline-block;
    vertical-align:top;

    &:first-of-type {
      @extend .fll;
    }

    &:last-of-type {
      margin-left:40px;
    }
  }

  .inline {

    label {
      vertical-align:middle;
    }
  }

  .inline.autocomplete {
     label {
       input[type="text"] {
         display:inline-block;

         margin-top:0;
       }
     }
  }
}