@import 'variables';

@media all and (max-width:$sm-width) {

  .cart {

    margin-top:0;

    &-view {

      .g-page-title {
        margin-top:0;
        margin-bottom:0;
      }
    }

    &__item {
      padding:83px 0 30px 120px;
      min-height:215px;

      display:flex;
      flex-direction:column;
      justify-content:start;

      &-product {
        flex:none;

        position:absolute;
        top:0;
        left:0;

        margin-top:30px;

        width:100px;

        a {
          display:inline-flex;
          flex-direction:column-reverse;
        }

        &__title {
          width:250%;
          margin:0;

          flex:none;

          font-size:14px;
          line-height:20px;
        }

        &__photo {
          margin-top:13px;

          width:100px;
          height:100px;
        }
      }

      &-price {
        width:auto;

        padding-top:0;

        b {
          display:inline-block;

          font-size:14px;
        }

        u {
          font-size:12px;

          margin-left:9px;
        }
      }

      &-total {
        text-align:left;

        padding-top:16px;

        width:auto;

        b {
          font-size:14px;
        }
      }

      &-actions {
        position:absolute;
        top:0;
        right:0;

        width:auto;

        padding:0;
        margin-top:30px;
      }

      &-amount.amount-wrapper {
        width:100%;
        margin-top:17px;

        label {
          display:inline-flex;
          flex-direction:row;

          width:100%;
          padding:0;

          u, b {
            position:relative;

            width:36px;
            height:36px;

            box-shadow:none;
          }

          input[type="text"] {
            width:auto;
            min-width:0;
            height:36px;

            line-height:36px;

            flex:1;
          }
        }
      }
    }

    &-list {

      &__header {
        display:none;
      }
    }

    &__bottom {
      margin-top:30px;

      text-align:center;

      > span {
        display:block;

        font-size:14px;
        line-height:1;
      }

      > .btn {
        width:100%;

        padding-left:0;
        padding-right:0;

        margin-top:26px;
      }
    }
  }
}
