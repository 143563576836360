@import 'variables';

@media all and (max-width:$sm-width) {

  .b-reviews-list {

    &__col {
      display:block;
      width:auto;
      max-width:none;

      margin-left:0 !important;
      margin-right:0 !important;
    }

    &__item {

      margin-bottom:30px;

      .rating {
        margin-bottom:20px;
      }

      &-content {
        padding:20px;

        &:before {
          margin-left:-6px;

          left:50%;
        }
      }

      &-body {
        font-size:14px;
        line-height:20px;
      }

      &-date {
        font-size:12px;
      }

      &-author {
        font-size:14px;
        text-align:center;
      }
    }
  }
}