@import 'variables';

.page-blog-index {
  max-width:980px;
  margin:0 auto;

  .pagination-wrapper {
    margin-top:30px;
  }
}

.blog {

  &-categories {

    margin:24px 0 19px;

    font-size:16px;
    line-height:1;

    &-wrapper {

      .css-mobile-dropdown-menu__title {
        display:none;
      }
    }

    li {
      display:inline-block;
      vertical-align:top;

      margin:0 22px;

      a {
        color:#8fa8b4;

        &:hover {
          color:$menu-active-link;
        }
      }

      &.active {
        color:$menu-active-link;
      }

      &:first-of-type {
        margin-left:0;
      }
    }
  }

  &-list {
    font-size:0;
    line-height:0;

    margin:0 -10px;

    &__item {
      display:inline-block;
      vertical-align:top;

      width:50%;
      max-width:480px;
      min-height:471px;

      padding:20px 20px 23px;
      margin:0 10px 20px;

      box-shadow:0 8px 21px rgba(66, 112, 131, 0.3);
      transition:box-shadow .4s;

      background:#fff;

      > span {
        display:block;
      }

      &-img {

        img {
          width:100%;
        }
      }

      &-date {
        opacity:0.6;
        font-size:14px;
        line-height:1;
        color:$color-link;

        margin:19px 0 8px;
      }

      &-title {
        width:80%;

        font-size:22px;
        line-height:26px;
        font-weight:700;
        color:$color-link;
      }

      &-lead {
        font-size:16px;
        line-height:22px;
        color:$color-link;

        margin-top:7px;
      }

      &:hover {
        box-shadow:0 8px 21px rgba(66, 112, 131, 0.5);
      }
    }
  }
}

@import 'blog-zmedia';
