@import 'variables';

.pagination {

  font-size:0;
  line-height:0;

  li {
    display:inline-block;
    vertical-align:top;

    font-size:16px;
    line-height:46px;
    text-align:center;
    font-weight:700;

    margin:0 3px;

    a, span {
      display:inline-block;
      vertical-align:top;

      width:46px;
      height:44px;

      background:#e9f1f3;

      border-radius:50%;
      transition:background-color .4s;
    }

    &.active {

      a {
        background:#d7fde0;
        color:$menu-active-link;
      }
    }

    a:hover {
      background:#d7fde0;
    }

    &.disabled {
      width:27px;

      a, span {
        background:none;
        margin:0;

        width:auto;
      }
    }

    &.arrow {
      width:46px;
      height:44px;
      line-height:44px;

      margin:0;

      a {
        background:none;

        display:inline-block;
        vertical-align:middle;

        width:10px;
        height:19px;
      }

      &-back {

        a {
          background:url(imgs/pagination-arrow-back.png) 0 0 no-repeat;

          &:hover {
            background-position:bottom left;
          }
        }
      }

      &-forward {

        a {
          background:url(imgs/pagination-arrow-forward.png) 0 0 no-repeat;

          &:hover {
            background-position:bottom left;
          }
        }
      }
    }
  }

  &-wrapper {

    @extend %clearfix;

    .load-more {
      @extend .flr;
    }
  }
}

@import 'pagination-zmedia';