@import 'variables';

.b-faq-list {

  @extend %clearfix;

  &__col {

    &-left {
      @extend .fll;

      width:255px;
    }

    &-right {
      max-width:685px;
      margin-left:300px;
    }
  }

  &__item {

    &-question {
      @extend .pr;

      border-top:2px solid #e9f1f3;

      font-size:22px;
      font-weight:700;
      line-height:26px;
      color:$color-link;
      cursor:pointer;

      padding:20px 20px 20px 0;

      &:after {
        @extend .icon-arrow;

        content:'';

        display:block;

        position:absolute;
        top:50%;
        right:0;

        margin-top:-5px;
      }
    }

    &-answer {
      display:none;

      border-top:2px solid #e9f1f3;

      padding:25px 20px 15px 0;
    }

    &:hover {

      .b-faq-list__item {

        &-question {
          color:$menu-active-link;

          &:after {
            background-position:0 -9px;
          }
        }
      }
    }

    &.active, &.active:hover {

      .b-faq-list__item {

        &-question {
          color:$menu-active-link;

          &:after {
            @extend .icon-arrow-active;
          }
        }

        &-answer {
          display:block;
        }
      }
    }
  }

  &-menu-title {
    font-size:16px;
    line-height:1;
    color:#8fa8b4;

    margin:0 0 15px;
  }

  &__contact {
    width:100%;

    border-top:2px solid #e9f1f3;
    padding-top:20px;

    display:flex;
    flex-direction:row;

    justify-content:space-between;

    &-text {
      max-width:395px;

      font-size:16px;
      line-height:22px;

      h2 {
        font-size:22px;
        font-weight:700;
        line-height:34px;
        color:$menu-active-link;
      }
    }

    &-button {
      margin-top:34px;
    }
  }
}

@import 'faq-zmedia';
