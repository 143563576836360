@import 'variables';

.wide-banners {

  &__item {
    @extend .pr;
    padding:5px;

    a {
      @extend .pr;

      display:block;

      max-width:967px;
      height:170px;

      text-decoration:none;

      background:no-repeat top center;
      -webkit-background-size:cover;
      background-size:cover;

      margin:0 auto;
      padding:40px 0 0;

      color:#fff;

      &:before {
        content:'';

        position:absolute;
        z-index:-1;

        top:0;
        left:0;
        right:0;
        bottom:0;

        margin:-5px;

        border:2px solid #e9f1f3;

        pointer-events:none;
      }
    }

    &-title {
      font-size:32px;
      line-height:34px;
      font-weight:700;

      display:block;

      max-width:50%;
      margin:0 auto;
    }

    &-subtitle {
      font-size:16px;
      line-height:22px;

      display:block;

      margin-top:14px;
    }

    &:hover {
      color:#fff;
    }
  }
}

@import 'wide-banners-zmedia';
