@import 'variables';

.calc {
  @extend .pr;

  max-width:970px;
  margin:0 auto;
  padding:1px 0 55px;

  &:before {
    content:'';

    position:absolute;
    z-index:-1;

    top:0;
    left:0;
    right:0;
    bottom:0;

    margin:-5px;

    border:2px solid #e9f1f3;

    pointer-events:none;
  }

  &-wrapper {
  }

  &-title {
    @extend .pr;

    font-size:32px;
    line-height:38px;
    font-weight:700;
    color:#3e758b;

    max-width:735px;
    margin:-26px auto 35px;

    span {
      display:inline-block;
      padding:0 10px;

      background:#fff;

      letter-spacing:.7px;

      i {
        font-style:normal;
        color:#8fa8b4;
      }

      u {
        text-decoration:none;
        color:$menu-active-link;
      }

      b {
        margin:0 15px;
      }
    }
  }

  &-subtitle {
    font-size:16px;
    line-height:22px;
    color:$color-link;

    letter-spacing:.27px;

    max-width:710px;
    margin:-10px auto 25px;
  }

  &-form {
    max-width:735px;
    margin:0 auto;

    .btn {
      padding:0 36px;
    }

    &__row {
      @extend %clearfix;

      text-align:left;

      margin:0 0 16px;

      &-66 {

        .calc-form__col {

          &:last-of-type {
            float:right;
          }
        }
      }

      &-39 {

        .calc-form__col {

          &:last-of-type {
            float:right;
          }
        }

        .calc_form__slider {
          padding-top:20px;
        }
      }

      &-333 {

        .calc-form__col {

          &:nth-child(2) {
            margin-left:36px;
          }

          &:last-of-type {
            float:right;
          }
        }

        .calc_form__slider {
          padding-top:20px;
        }
      }
    }

    &__col {
      @extend .fll;

      &-3 {
        width:25.5%;
      }

      &-6 {
        width:48%;
      }

      &-9 {
        width:69.5%;
      }
    }

    &__field {
      @extend .pr;

      label {

        .label- {
          margin-bottom:8px;
        }

        select {
          width:100%;
        }
      }
    }

    &__presets {
      @extend .tac;

      font-size:0;
      line-height:0;
      white-space:nowrap;

      margin:24px 0 24px;

      a {
        @extend .pr;

        display:inline-block;
        vertical-align:top;
        text-decoration:none;

        width:92px;
        height:58px;

        .icon {
          margin-bottom:18px;
        }

        span {
          display:block;

          font-size:14px;
          line-height:1;
          color:#8fa8b4;
        }

        &:first-of-type:before, &:after {
          content:'';

          position:absolute;
          top:0;
          left:0;

          width:2px;
          height:100%;

          background-color:#e9f1f3;
        }

        &:after {
          left:auto;
          right:0;
        }

        &:hover, &.active {

          span {
            color:$menu-active-link;
            font-weight:700;
          }
        }
      }
    }
  }

  &-results {
    @extend .tac;

    .calc {

      &-subtitle {
        max-width:410px;
      }
    }

    .text-muted {
      max-width:555px;
      margin:0 auto;
    }

    &__content {

      &-variants {
        font-size:0;
        line-height:0;

        &__item {
          @extend .pr;

          display:inline-block;
          vertical-align:text-top;
          font-weight:700;

          height:100px;
          padding:5px 40px;

          font-size:16px;
          line-height:22px;

          span {
            display:block;
            width:100%;

            height:40px;

            position:absolute;
            bottom:0;
            left:0;

            margin:0 0 5px;

            b {
              color:$color-link;
            }

            i {
              display:block;

              color:#8fa8b4;
              font-style:normal;
            }
          }

          u {
            display:inline-block;
            vertical-align:top;

            max-width:135px;

            color:$menu-active-link;
            text-decoration:none;
          }

          &:first-of-type:before, &:after {
            content:'';

            position:absolute;
            top:0;
            right:0;

            width:2px;
            height:100%;

            background-color:#e9f1f3;
          }

          &:before {
            right:auto;
            left:0;
          }

          &:hover {
            background:#ebfeef;

            span {

              i, b {
                color:$menu-active-link;
              }
            }
          }
        }
      }

      &-buttons {
        font-size:0;
        line-height:0;

        margin:37px 0 34px;

        .btn {
          margin:0 16px;
        }
      }
    }
  }
}
