@import 'variables';

@media all and (max-width:$sm-width) {

  .mobile-menu-trigger {
    display:block;
  }

  .header {
    padding-left:$xs-padding;
    padding-right:$xs-padding;

    height:50px;

    .header {

      &-menu, &-auth, &-lang {
        display:none;
      }

      &-cart {
        margin:-14px 20px 0;

        position:absolute;
        top:50%;
        right:0;

        a {
        }

        .icon {
          margin:0;
        }

        u {
          display:none;
        }
      }
    }

    .logo {
      width:98px;

      left:50%;
      margin:0 0 -20px -49px;
    }
  }
}
