@import 'variables';

@media all and (max-width:$sm-width) {

  .payments {
    margin-left:$xs-padding;
    margin-right:$xs-padding;

    padding-bottom:35px;

    &__tabs {

      a {
        font-size:14px;
        line-height:18px;
        white-space:normal;

        padding:17px 25px 0;
      }
    }

    &__content {
      padding:25px 20px 0;

      &-item {

        &__top-text {

          h3 {
            font-size:18px;
            line-height:22px;
          }

          p {
            font-size:14px;
            line-height:20px;
          }
        }

        &__points {

          &-item {
            display:block;
            width:auto;

            margin-bottom:36px;

            &:before, &:after {
              display:none;
            }

            &__title {
              font-size:18px;
              line-height:36px;

              .icon {
                width:36px;
                height:36px;

                background-size:contain;
              }
            }

            &__lead {
              font-size:14px;
              line-height:20px;

              margin:10px 0 14px;
              min-height:0;

              br {
                display:none;
              }
            }

            > .btn {
            }
          }
        }
      }

      &-banner {
        font-size:18px;
        line-height:22px;

        padding:47px 25px 45px;
        margin:84px auto 55px;

        br {
          display:none;
        }

        .icon {

          &-calendar {
            margin:-36px 0 0 -39px;

            top:0;
            left:50%;
          }

          &-box {
            margin:0 0 -42px -32px;

            top:auto;
            bottom:0;
            left:50%;
          }
        }
      }
    }
  }
}
