@import 'variables';

@media all and (max-width:$sm-width) {

  .b-faq-list {

    &__col {

      &-left, &-right {
        float:none;

        width:auto;
        max-width:none;

        margin-left:0;
      }

      &-left {
        .categories-menu {
        }
      }

      &-right {
      }
    }

    &__item {

      &-question {
        font-size:18px;
        line-height:22px;

        padding:15px 20px 17px 0;
      }
    }

    &__contact {
      display:block;

      &-text {
        max-width:none;

        font-size:14px;
        line-height:20px;

        br {
          display:none;
        }

        h2 {
          font-size:18px;
          line-height:22px;
        }
      }

      &-button {
        margin:20px 0 0;

        padding-left:0;
        padding-right:0;

        width:100%;
      }
    }
  }
}
