@import 'variables';

@media all and (max-width:$sm-width) {

  .delivery-view {

    .g-page-title {
      margin-top:0;
    }

    &__info {
      margin-top:24px;

      text-align:center;

      > img {
        float:none;

        max-width:100%;
        margin-bottom:17px;
      }

      &-item {
        margin-left:0;
        margin-bottom:25px;

        padding-left:95px;
        max-height:none;

        text-align:left;
      }
    }

    .common-content {

      .link2back {
        text-align:left;
        display:inline-block;
      }
    }

    &__buttons {
      white-space:normal;
      margin-top:30px;

      .btn {

        &, &:last-of-type {
          width:100%;
          float:none;
        }

        + .btn {
          margin-top:20px;
        }
      }
    }
  }

  .how-pay {
    margin-top:12px;

    &-item {

      &__title {
        font-size:18px;
      }

      &__lead {
        font-size:14px;
        line-height:20px;
      }

      &,
      &:nth-child(2),
      &:nth-child(3) {
        display:block;
        width:100%;

        margin:0;
        padding-bottom:26px;
      }

      &:last-of-type {
        padding-bottom:0;
      }
    }
  }
}