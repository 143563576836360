@import 'variables';

.header {
  @extend .pr;

  .logo {
    position:absolute;
    bottom:0;
    left:0;

    margin-bottom:-48px;

    img {
      max-width:100%;
    }
  }

  &-wrapper {
    @extend .pr;

    background:$top-footer-color;
    z-index:1;
  }

  &-lang, &-auth, &-cart {
    display:inline-block;
    vertical-align:top;

    margin-top:19px;
    font-weight:700;

    a, span {
      display:inline-block;
      vertical-align:middle;

      font-size:16px;
      line-height:28px;
      white-space:nowrap;

      .icon {
        vertical-align:middle;

        margin:-2px 4px 0 0;
      }
    }

    span {
      color:$menu-active-link;
    }

    u {
      text-decoration:none;
    }

    > i {
      display:inline-block;
      vertical-align:top;

      width:1px;
      height:28px;

      background:$color-link;
      opacity:.2;

      margin:0 11px;
    }
  }

  &-lang {
    @extend .flr;
  }

  &-auth {
    @extend .flr;

    font-size:0;
    line-height:0;

    margin-right:59px;
  }

  &-cart {
    @extend .flr;

    margin-right:48px;

    a {

      .icon-cart {
        @extend .pr;

        margin-right:7px;

        u {
          display:none;
          height:15px;

          padding:0 5px;

          position:absolute;
          top:0;
          right:0;

          margin:-5px;

          text-decoration:none;

          border-radius:8px;
          background:#16beb2 linear-gradient(-233deg, #68de84 0%, #13bdb3 75%, #00adaf 100%);

          font-size:10px;
          line-height:17px;
          font-weight:700;
          color:#fff;
        }
      }
    }
  }

  &-menu {
    display:inline-block;
    vertical-align:top;
    margin-left:206px;

    font-size:0;
    line-height:0;

    ul {

      li {
        @extend .pr;

        height:63px;
        margin:0 12px;

        display:inline-block;
        vertical-align:top;

        font-size:16px;
        line-height:67px;
        font-weight:700;

        &.active {
          a {
            color:$menu-active-link;
          }

          &:before {
            content:'';

            display:block;
            height:4px;

            position:absolute;
            bottom:0;
            left:0;
            right:0;

            background:#66dd85 linear-gradient(to right, #16beb1 0%, #16beb1 49%, #51d591 100%);
          }
        }

        &:first-of-type {
          margin-left:0;
        }
      }
    }
  }
}

@import 'header-zmedia';
