@import 'variables';

.categories {

  &-list {
    font-size:0;
    line-height:0;

    a {
      display:inline-block;
      vertical-align:top;

      font-size:14px;
      line-height:32px;
      color:#3e758c;

      padding:0 20px;
      margin:0 8px 8px 0;

      background:#e9f1f3;

      border-radius:15px;

      &.active, &:hover {
        background:#ebfeef;
        color:$menu-active-link;
      }
    }
  }

  &-actions {
    margin-top:13px;

    a {
      font-size:14px;
      line-height:22px;
      font-weight:700;
      color:#8fa8b4;

      &:hover {
        color:$menu-active-link;
      }

      &.reverse {

        .icon-arrow-gray {
          background-position:0 -8px;
        }

        &:hover {

          .icon-arrow-gray {
            background-position:0 -24px;
          }
        }
      }
    }

    s {
      display:inline-block;
      vertical-align:top;

      width:2px;
      height:20px;

      background:#e9f1f3;

      margin:0 25px;
    }

    .icon-arrow-gray {
      vertical-align:middle;

      margin:-2px 0 0 8px;
    }

    .icon-erase {
      vertical-align:middle;

      margin:-3px 8px 0 0;
    }
  }

  &-menu {

    @extend .pr;
    box-shadow:0 8px 21px rgba(66, 112, 131, 0.3);

    background:#fff;

    margin:0 0 30px;

    /*&:before {
      content:'';

      display:block;
      width:13px;
      height:6px;

      position:absolute;
      top:0;
      left:0;

      margin:-6px 0 0 21px;

      background:url(imgs/faq-menu-tr.png) 0 0 no-repeat;
    }*/

    ul {
      @extend .pr, .ovh;
      z-index:1;

      li {
        @extend .pr;

        display:block;
        margin:0;

        font-size:16px;
        line-height:20px;
        font-weight:700;

        &:after {
          content:'';

          position:absolute;
          bottom:0;
          left:20px;
          right:20px;

          display:block;
          height:2px;

          background:#e9f1f3;
        }

        a, span {
          display:block;
          vertical-align:top;

          padding:17px 30px;
        }

        &.active, &:hover {

          &, a {
            color:#00af67;
          }

          &:before {
            content:'';

            display:block;
            width:100%;

            position:absolute;
            top:-2px;
            bottom:0;
            left:0;

            z-index:-1;

            background:#d7fde0;

            pointer-events:none;
          }
        }

        &:last-of-type {
          &:after {
            content:none;
          }
        }
      }
    }
  }
}

.page-sales {

  .css-mobile-dropdown-menu__title {
    display:none;
  }
}
